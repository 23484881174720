import { SVGProps, Ref, forwardRef } from 'react'

const SvgLinkedIn = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width="1.5rem"
    height="1.5rem"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15.569 12.468a2.068 2.068 0 0 0-2.066 2.07v7.24H8.337s.061-12.413 0-13.447h5.166v1.536s1.599-1.493 4.069-1.493c3.059 0 5.228 2.217 5.228 6.521v6.884h-5.165v-7.242a2.068 2.068 0 0 0-2.066-2.069ZM3.679 6.262h-.03c-1.562 0-2.57-1.223-2.57-2.567 0-1.375 1.04-2.495 2.63-2.495 1.592 0 2.57 1.156 2.602 2.531 0 1.345-1.01 2.53-2.633 2.53ZM6.27 21.779H1.106V8.33h5.165v13.448Z"
      clipRule="evenodd"
    />
  </svg>
)

const ForwardRef = forwardRef(SvgLinkedIn)
export default ForwardRef
